$border-selected: 1px solid white;
$zIndex-selected: 999;
$box-shadow-selected:
  0px 0px 24px 0px rgba(255, 255, 255, 0.3),
  0px 8px 12px 0px rgba(0, 155, 224, 0.5),
  0px 0px 9px 0px rgba(0, 155, 224, 0.6),
  0px 4px 4px 0px rgba(0, 0, 0, 0.25);

$box-shadow-selected-defect:
  0px 0px 24px 0px #ca3a314d,
  0px 8px 12px 0px #ca3a3180,
  0px 0px 5px 5px #ca3a3199;

$box-shadow-selected-confirmed:
  0px 0px 24px 0px #009be099,
  0px 8px 12px 0px #009be099,
  0px 0px 5px 5px #009be099;

$box-shadow-selected-treated:
  0px 0px 24px 0px #8f48a7,
  0px 8px 12px 0px #8f48a7,
  0px 0px 5px 5px #8f48a7;

@mixin marker-pin {
  width: 21px;
  height: 21px;
  border-radius: 72% 72% 72% 0;
  background: #666666;
  transform: rotate(-45deg);
  margin: -20px 0 0 -12px;

  .marker-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    color: #fff;
    font-weight: 400;
    font-family:
      Noto Sans JP,
      sans-serif;
  }
  .invisible {
    opacity: 0.5;
    text-decoration: line-through;
  }
}

@mixin selected-label {
  width: 32.2px;
  height: 32.2px;
  border: $border-selected;
  box-shadow: $box-shadow-selected;
  .marker-label {
    line-height: 16px;
    font-weight: 400;
  }
}

@mixin selected-label-task {
  width: 34px;
  height: 34px;
}

.forge-viewer {
  div {
    :has(.docking-panel-container-solid-color-a) {
      z-index: 1000 !important;
    }
  }
  .left-50vw {
    left: calc(50vw - 150px) !important;
  }
  .docking-panel {
    z-index: 1000 !important;
  }
  .adsk-viewing-viewer {
    .docking-panel:has(.task-in-forge-viewer) {
      overflow-x: unset !important;
      overflow-y: unset !important;
    }
    .progressbg {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .homeViewWrapper {
    display: none;
  }
}

.document-label {
  .marker-pin {
    @include marker-pin;
    border: $border-selected;
    &:hover {
      opacity: 0.9;
      transition: opacity 0.2s ease;
    }
  }
  &.selected-label {
    z-index: $zIndex-selected;
    .marker-pin {
      margin: -32px 0 0 -16px;
      @include selected-label;
      transition: all 0.1s ease;
    }
  }
}
.task-label {
  .marker-pin {
    @include marker-pin;
    &:hover {
      opacity: 0.9;
      transition: opacity 0.2s ease;
    }
  }
  &.selected-label {
    z-index: $zIndex-selected;
    .marker-pin {
      margin: -34px 0 0 -17px;
      @include selected-label-task;
      transition: all 0.15s ease;
      .marker-label {
        svg {
          width: 16px;
          height: 16px;
        }

        #treated {
          svg {
            width: 26px !important;
            height: 26px !important;
          }
        }
      }
    }
    &.treated {
      .marker-pin {
        box-shadow: $box-shadow-selected-treated;
      }
    }
    &.defect {
      .marker-pin {
        box-shadow: $box-shadow-selected-defect;
      }
    }
    &.confirmed {
      .marker-pin {
        box-shadow: $box-shadow-selected-confirmed;
      }
    }
  }
}
.area-combined-child-label-wrapper {
  padding: 2px;
  .marker-pin {
    @include marker-pin;
    margin: 0px;
    margin-top: -4px;
    margin-right: 3px;
  }
}

.task-in-forge-viewer {
  width: 24px;
  height: 28px;
  .marker-pin {
    @include marker-pin;
  }
}

.homeViewWrapper {
  display: none;
}

label.markup {
  position: absolute;
  white-space: nowrap;
  cursor: pointer;
}

label.disable-click {
  cursor: initial !important;

  .label-title-tooltip {
    display: none !important;
  }
}

.disable-label-title-tooltip {
  display: none !important;
}

// label.update {
//   z-index: 1;
// }

label .pass {
  color: #2185d0;
}

label .power {
  color: #fbbd08;
}

label .sensor {
  color: #db2828;
}

div.pass {
  background-color: #2185d0;
}

div.power {
  background-color: #fbbd08;
}

div.sensor {
  background-color: #db2828;
}

#viewer {
  position: absolute;
  width: 80%;
  height: 100%;
}

#sidebar {
  position: absolute;
  right: 0;
  width: 20%;
  height: 100%;
}

#container-chat-inspection-item span.mention-item {
  color: #575f67;
  cursor: pointer;
  display: inline-block;
  background: #e6f3ff;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none;
}

.label-status-icon {
  height: 100%;
  align-items: center;
  display: flex;
}

.label-status-icon svg {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0;
}

.task-label .body .image-count {
  text-align: center;
  background-color: #ffffff;
  border-radius: 50%;
  width: 1.8rem;
  position: absolute;
  left: 1.7rem;
  top: 1rem;
  font-size: 1.1rem;
}

.combined-label,
.combined-area-label {
  position: relative;
  padding: 1rem;
  border-radius: 5px;
  box-sizing: border-box;

  &.selected-label {
    z-index: 999 !important;
  }

  &:has(.children:empty) {
    z-index: 998 !important;
  }
}

.combined-area-label {
  background-color: initial !important;
}

.combined-area-label > .body {
  background-color: #858585;
}

.combined-label > .body {
  color: #ffffff;
  background-color: #ce2121;
  text-align: center;
  position: relative;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  width: 72px;
  height: 34px;
  display: flex;
  align-items: center;
}

.combined-label > .body .card {
  padding: 0.4rem;
  background-color: var(--blue);
  text-align: center;
  position: absolute;
  border-radius: 3px;
  width: 4rem;
  border: 1px solid #ffffff;
  height: 2.4rem;
}

.combined-label > .body > .combined-label-title,
.combined-area-label > .body > .combined-label-title {
  width: 100%;
  text-align: center;
}

.combined-label > .children {
  box-shadow: 0 0 5px 5px #68bbff;
  position: absolute;
  left: calc(100% + 1px);
  top: 0;
  z-index: 999;
  background-color: #ffffff;
  padding: 2px;
  border-radius: 3px;
  max-height: 18.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &:empty {
    display: none;
  }

  &.children-include-both {
    max-width: 290px;

    .area-label {
      max-width: none;
    }
  }
}

label.area-label {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 280px;
  background-color: var(--table-alternate-background);
  border: 2px solid #808080;
  border-radius: 3px;

  .area-title {
    position: relative;
    cursor: pointer;
    padding: 5px 3px;
    background-color: #808080;
    z-index: 999;

    .text-ellipsis {
      white-space: pre;
    }

    .area-title-tooltip {
      position: absolute;
      white-space: pre;
      left: 0;
      top: calc(100% + 5px);
      padding-top: 8px;
      padding-bottom: 8px;
      max-width: none;
      display: none;
    }

    &:hover {
      .area-title-tooltip {
        display: block;
      }
    }
  }

  // Update color
  border-color: var(--status-color, #808080);

  .area-title {
    background-color: var(--status-color, #808080);
  }

  &.selected-label {
    box-shadow: 0 0 5px 6px #68bbff;
  }
  .combined-child-title {
    max-width: 200px;
  }
}

.group-children label.area-label {
  position: static;
  // max-width: none;
  width: auto;
  z-index: 2;
  overflow: hidden;
  box-sizing: border-box;
}

.area-document-container {
  z-index: 101;
  padding: 2px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.area-combined-label {
  z-index: 102;
  position: absolute;

  > .body {
    width: 70px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &.area-combined-label-hightlight,
  &.largest-z-index {
    > .body {
      box-shadow: 0 0 5px 5px #68bbff;
    }
  }

  .children {
    background-color: var(--table-alternate-background);
    border: 2px solid #808080;
    display: flex;
    flex-direction: column;
    gap: 1px;
    border-radius: 3px;
    padding: 2px;
    overflow-y: auto;
    max-height: 300px;
    max-height: min(300px, calc(100vh - 500px));

    .document-category-title {
      color: white;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      padding: 2px 2px;
    }
  }
}

.combined-label > .children .group-children {
  &:not(.area-group-children) {
    background-color: var(--table-alternate-background);
    border: 2px solid rgb(0, 125, 179);
    border-radius: 3px;
    padding: 2px;
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
}

.combined-label > .children label {
  cursor: pointer;
}

.combined-label > .children .group-children label {
  cursor: pointer;
}

.combined-label > .children .fixed-width {
  min-width: 4rem;
}

.combined-child-label .body {
  display: flex;
  padding: 0.2rem;
  border-radius: 3px;
  gap: 5px;
  align-items: center;
  min-height: 24px;
  min-width: 3.5px;
}

.document-label {
  position: relative;
}

.document-label .body {
  color: #171717;
  display: flex;
  // gap: 0.4rem;
  cursor: pointer;
  border-radius: 4px;
  border-bottom-left-radius: unset;

  .group-child {
    display: flex;
    gap: 0.4rem;
    justify-content: center;
    background-color: #fff;
    padding: 0.2rem;
  }

  box-sizing: border-box;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.p-4 {
  padding: 0.4rem;
}

.document-label .body .image-count {
  text-align: center;
  background-color: #ffffff;
  border-radius: 50%;
  width: 1.8rem;
  position: absolute;
  left: 1.7rem;
  top: 1rem;
  font-size: 1.1rem;
}

.document-label.selected-label > .label-arrow {
  border-top: 1rem solid #61b8ff;
  z-index: 510 !important;
}

.label-arrow {
  width: 4px;
  height: 0;
  border-top: 1rem solid white;
  border-right: 1rem solid transparent;
  position: absolute;
  top: 100%;
  border-bottom-left-radius: 2px;
}

.adsk-viewing-viewer #toolbar-orbitTools #toolbar-orbitToolsSubMenu,
.adsk-viewing-viewer #toolbar-orbitTools #toolbar-orbitToolsarrow {
  display: none;
}

.group-children:not(.area-group-children) .selected-label,
.area-document-container .selected-label,
.combined-child-label.selected-label {
  box-shadow: none;
  background-color: #61b8ff;
}

.children > .selected-label,
.flex-column > .selected-label {
  z-index: 98 !important;
}

.selected-label > .flex-column > .label-item,
.selected-label > .body,
.selected-area-label {
  box-shadow: 0 0 5px 5px #68bbff;
}

.selected-label > .flex-column > .selected-label > .flex-column,
.selected-label > .body > .flex-column,
.selected-label > .flex-column > .body > .flex-column,
.selected-label > .children > .selected-label > .combined-child-label {
  background-color: #68bbff !important;
}

.position-relative {
  position: relative;
}

#label-display-setting-checkbox-group,
#label-filter-checkbox-group,
#show-main-model-element-button-group {
  position: absolute;
  bottom: 55px;
  left: 0;
  padding: 2rem;
  background-color: #2a2a2a;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: max-content;
  z-index: 1000;
  color: #fff;
}

#label-display-setting-checkbox-group h1,
#label-filter-checkbox-group h1,
#show-main-model-element-button-group h1 {
  white-space: nowrap;
  font-weight: bold;
}

#label-display-setting-checkbox-group span,
#label-filter-checkbox-group span,
#show-main-model-element-button-group span {
  white-space: nowrap;
  text-align: left;
}

#label-display-setting-checkbox-group .checkbox-with-label,
#label-filter-checkbox-group .checkbox-with-label,
#show-main-model-element-button-group .checkbox-with-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;
  width: 100%;
}

.label-filter-check-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkbox-with-label input[type="checkbox"] {
  position: relative;
}

.checkbox-with-label input[type="checkbox"]::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -2px;
  left: 0;
  border: 2px solid #fff;
  border-radius: 2px;
  background-color: #2a2a2a;
  color: #ccc;
}

.checkbox-with-label input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -2px;
  left: 0;
  background-color: #fff;
}

.checkbox-with-label input[type="checkbox"]:checked::after {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 5px;
}

#label-display-setting-checkbox-group .checkbox-with-label label,
#label-filter-checkbox-group .checkbox-with-label label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

#label-display-setting-checkbox-group .checkbox-with-label label .flex,
#label-filter-checkbox-group .checkbox-with-label label .flex {
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  border-radius: 4px;
}

.label-extension-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}

.selected-tool {
  outline: 1px solid #73d3ff;
}

.checkmark {
  transform: translateX(-1px) rotate(30deg);
  height: 2rem;
  width: 0.5rem;
  margin-bottom: 2px;
  border-bottom: 2px solid #18a881;
  border-right: 2px solid #18a881;
}

.notgood {
  height: 100%;
  color: #db2828;
  font-weight: bold;
  font-size: 1.1rem;
}

@media screen and (max-width: 667px), screen and (max-height: 476px) {
  .forge-viewer #navTools,
  #modelTools,
  #settingsTools,
  #set-visible-control-group {
    display: none;
  }
}

.label-item-displayTitle {
  display: block;
  color: #171717;
  font-size: 14px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.unVisible-title {
  opacity: 0.5;
  text-decoration: line-through !important;
}

.label-item-displayPriority {
  width: 24px;
  font-size: 14px;
  font-weight: 500;
}

.label-item-displayId {
  width: fit-content;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  padding: 0 6px !important;
}

.label-item-displayPhoto {
  display: flex;
  justify-content: center;
  width: 32px;
  font-weight: 500;
  color: #171717;
}

.label-item-containerTitle,
.label-item-containerSubTitle {
  position: relative;

  &:hover .label-title-tooltip {
    display: block;
  }
}

.label-title-tooltip {
  display: none;
  position: fixed;
  z-index: 99999;
  background-color: #fff;
  color: #171717;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  box-shadow: 1px 3px 10px 0 rgb(0 0 0 / 50%);
  width: max-content;
  text-align: left;
  max-width: 180px;
  word-break: break-all;
  white-space: normal;
  pointer-events: none;
}

// .group-children {
//   .label-title-tooltip {
//     position: fixed;
//     z-index: 99999;
//   }
// }

.label-title-tooltip::-webkit-scrollbar-track {
  border-radius: 4px;
}

.label-title-tooltip::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.label-item-displayBlackBoard {
  margin-left: -3px;
}

.label-title,
.label-item-containerSubTitle > .label-item-displayTitle {
  max-width: 180px;
}

.combined-child-title {
  max-width: 100px;
}

.combined-child-title-item {
  max-width: 70px;
}

@media only screen and (max-width: 1210px) {
  .label-item-displayTitle,
  .label-item-displayPriority,
  .label-item-displayId,
  .label-title-tooltip {
    font-size: 10px;
  }

  .task-label .body .flex,
  .combined-child-label .body .flex,
  .document-label .body .flex {
    height: 18px;
    padding: 0 0.2rem;
  }

  .label-item-displayId,
  .label-item-displayPriority,
  .label-item-displayPhoto {
    width: fit-content;
  }

  .label-status-icon svg {
    height: 20px !important;
  }

  .label-title {
    max-width: 132px;
  }

  .flex-row > .label-item-displayBlackBoard {
    position: relative;
    top: -2px;
  }

  .combined-label .icon-camera {
    position: relative;
    top: -1px;
  }

  .combined-child-title {
    max-width: 70px;
  }

  .combined-child-title-item {
    max-width: 50px;
  }
}

.combined-child-label-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  .label-status-icon {
    align-self: stretch;
    height: auto;
    border-radius: 3px 0 0 3px;
  }

  .combined-child-label {
    flex: 1;

    .label-item {
      border-radius: 0 3px 3px 0;
    }
  }
  .selected-label {
    > .body {
      box-shadow: none !important;
    }
  }
}

.family-edit-form {
  background: white;
  width: 330px;
  padding: 15px;
  border: 2px solid #cbc6c6;
  border-radius: 0px 5px 5px 0px;
  max-height: calc(100vh - 200px);
  max-height: min(calc(100vh - 200px), 500px);
  display: flex;
  flex-direction: column;
}
