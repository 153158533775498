.selected-component {
  z-index: 2;
}

.react-draggable-dragging {
  z-index: 1;
}

.selected-td {
  box-shadow: 0 0 0 2px #1795fe inset !important;
}


.blackboard-image {
  @media print {
    max-height: 99%
  }
}

.chakra-modal__content-container {
  padding: 1.5rem;
}